import PropTypes from 'prop-types';
import { Redirect, Router, Switch } from 'react-router-dom';

import AppRoute from 'containers/App/Route';
import NotFoundPage from 'containers/NotFoundPage/Loadable';
import OveroddsReportTipstersPage from 'containers/Overodds/Report/Tipsters/Loadable';
import OveroddsRoundPage from 'containers/Overodds/RoundPage/Loadable';
import OverviewPage from 'containers/OverviewPage/Loadable';
import AuthorPage from 'containers/Shared/Author/Loadable';
import ContentPage from 'containers/Shared/Content/Loadable';
import DeskedContentPage from 'containers/Shared/DeskedContent/Loadable';
import MeetPage from 'containers/Shared/Meet/Loadable';
import PushNotificationPage from 'containers/Shared/PushNotificationPage/Loadable';
import AutotextStatisticsPage from 'containers/Shared/Report/AutotextStatistics';
import ReportSystemPage from 'containers/Shared/Report/SystemResults/Loadable';
import TipsterStatisticsPage from 'containers/Shared/Report/TipsterStatistics';
import RoundPage from 'containers/Shared/Round/Loadable';
import SitesPage from 'containers/SitesPage/Loadable';
import TrAisExportPage from 'containers/TrAis/ExportPage/Loadable';
import TrAisRaceRedirect from 'containers/TrAis/RaceRedirect/Loadable';
import TrAisV75CalendarPage from 'containers/TrAis/V75CalendarPage/Loadable';

const routes = {
  content: [
    'andelsspel',
    'galopptips',
    'joker_sport',
    'jokersystemet',
    'jokersystemet_no',
    'overodds',
    'spelvarde',
    'travronden',
    'travronden_spel',
    'trhub',
    'trmedia',
  ],
  author: [
    'andelsspel',
    'external_atg',
    'external_atg_international',
    'external_bet25',
    'external_rikstoto',
    'external_tr_spel_b2b',
    'galopptips',
    'joker_sport',
    'jokersystemet',
    'jokersystemet_no',
    'overodds',
    'spelvarde',
    'travronden',
    'travronden_spel',
    'trmedia',
  ],
  meet: [
    'external_atg',
    'external_atg_international',
    'external_bet25',
    'external_rikstoto',
    'external_tr_spel_b2b',
    'galopptips',
    'spelvarde',
    'travronden_spel',
    'trais',
  ],
  round: [
    'external_atg',
    'external_atg_international',
    'external_bet25',
    'external_rikstoto',
    'external_tr_spel_b2b',
    'galopptips',
    'spelvarde',
    'travronden_spel',
  ],
  pushNotification: ['galopptips', 'travronden'],
};

const App = ({ history }) => (
  <Router history={history}>
    <Switch>
      <AppRoute
        exact
        path="/sites"
        component={SitesPage}
        permissions="logged in"
      />

      <AppRoute
        exact
        path="/overview"
        component={OverviewPage}
        permissions="logged in"
      />

      {routes.content.map(site => (
        <AppRoute
          key={site}
          path={`/${site}/content/:id?/:action?`}
          site={site}
          component={ContentPage}
          permissions="logged in"
        />
      ))}

      {routes.author.map(site => (
        <AppRoute
          key={site}
          path={`/${site}/authors/:id?/:action?`}
          site={site}
          component={AuthorPage}
          permissions="logged in"
        />
      ))}

      {routes.meet.map(site => (
        <AppRoute
          key={site}
          path={`/${site}/meet/:id?/:tab?`}
          site={site}
          component={MeetPage}
          permissions="logged in"
        />
      ))}

      {routes.round.map(site => (
        <AppRoute
          key={site}
          path={`/${site}/round/:id?/:tab?`}
          site={site}
          component={RoundPage}
          permissions="logged in"
        />
      ))}

      {routes.pushNotification.map(site => (
        <AppRoute
          key={site}
          path={`/${site}/push_notifications/:id?/:tab?`}
          site={site}
          component={PushNotificationPage}
          permissions="logged in"
        />
      ))}

      {/* Joker Sport */}
      <AppRoute
        path="/joker_sport/rounds/:id?/:tab?/:content_id?"
        site="joker_sport"
        component={OveroddsRoundPage}
        permissions="logged in"
      />

      {/* Överodds */}
      <AppRoute
        path="/overodds/rounds/:id?/:tab?/:content_id?"
        site="overodds"
        component={OveroddsRoundPage}
        permissions="logged in"
      />
      <AppRoute
        path="/overodds/reports/system"
        site="overodds"
        component={ReportSystemPage}
        permissions="logged in"
      />
      <AppRoute
        path="/overodds/reports/tipsters"
        site="overodds"
        component={OveroddsReportTipstersPage}
        permissions="logged in"
      />
      <Redirect from="/overodds/reports" to="/overodds/reports/system" />

      {/* Spelvärde */}
      <AppRoute
        path="/spelvarde/reports/tipster-statistics"
        site="spelvarde"
        component={TipsterStatisticsPage}
      />
      <AppRoute
        path="/spelvarde/reports/system_results"
        site="spelvarde"
        component={ReportSystemPage}
        permissions="logged in"
      />
      <Redirect
        from="/spelvarde/reports"
        to="/spelvarde/reports/system_results"
      />

      {/* Travronden */}
      <AppRoute
        path="/travronden/desked-content"
        site="travronden"
        component={DeskedContentPage}
        permissions="logged in"
      />

      {/* Travronden Spel */}
      <AppRoute
        path="/travronden_spel/reports/tipster-statistics"
        site="travronden_spel"
        component={TipsterStatisticsPage}
      />
      <AppRoute
        path="/travronden_spel/reports/system_results"
        site="travronden_spel"
        component={ReportSystemPage}
        permissions="logged in"
      />
      <Redirect
        from="/travronden_spel/reports"
        to="/travronden_spel/reports/system_results"
      />

      {/* TRAIS */}
      <AppRoute
        exact
        path="/trais/exports"
        site="trais"
        component={TrAisExportPage}
        permissions="logged in"
      />
      <AppRoute
        exact
        path="/trais/v75-calendar/:id?/:tab?"
        site="trais"
        component={TrAisV75CalendarPage}
        permissions="logged in"
      />
      <AppRoute
        exact
        path="/trais/race-redirect"
        site="trais"
        component={TrAisRaceRedirect}
        permissions="logged in"
      />

      {/* External ATG */}
      <AppRoute
        path="/external_atg/reports/autotext-statistics"
        site="external_atg"
        component={AutotextStatisticsPage}
      />

      {/* External TR Spel B2B */}
      <AppRoute
        path="/external_tr_spel_b2b/reports/autotext-statistics"
        site="external_tr_spel_b2b"
        component={AutotextStatisticsPage}
      />

      <Redirect from="/" to="/sites" />
      <AppRoute path="*" component={NotFoundPage} />
    </Switch>
  </Router>
);

App.propTypes = {
  history: PropTypes.object,
};

export default App;
