import auth from 'containers/Auth/auth';

export const nav = {
  andelsspel: [
    {
      title: true,
      name: 'Andelsspel',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Innehåll',
      url: '/andelsspel/content',
      icon: 'icon-list',
    },
    {
      name: 'Skribenter',
      url: '/andelsspel/authors',
      icon: 'icon-people',
    },
  ],
  galopptips: [
    {
      title: true,
      name: 'Galopptips',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Innehåll',
      url: '/galopptips/content',
      icon: 'icon-list',
    },
    {
      name: 'Tävlingsdagar',
      url: '/galopptips/meet',
      icon: 'icon-flag',
    },
    {
      name: 'Omgångar',
      url: '/galopptips/round',
      icon: 'icon-calculator',
    },
    {
      name: 'Hallå!',
      url: '/galopptips/push_notifications',
      icon: 'icon-bell',
    },
    {
      name: 'Skribenter',
      url: '/galopptips/authors',
      icon: 'icon-people',
    },
  ],
  overodds: [
    {
      title: true,
      name: 'Överodds',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Innehåll',
      url: '/overodds/content',
      icon: 'icon-list',
    },
    {
      name: 'Omgångar',
      url: '/overodds/rounds',
      icon: 'icon-calculator',
    },
    {
      name: 'Skribenter',
      url: '/overodds/authors',
      icon: 'icon-people',
    },
    {
      name: 'Rapporter',
      url: '/overodds/reports',
      icon: 'icon-graph',
      children: [
        {
          name: 'Tipsresultat',
          url: '/overodds/reports/system',
        },
        {
          name: 'Tipster-statistik',
          url: '/overodds/reports/tipsters',
        },
      ],
    },
  ],
  spelvarde: [
    {
      title: true,
      name: 'Spelvärde',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Innehåll',
      url: '/spelvarde/content',
      icon: 'icon-list',
    },
    {
      name: 'Tävlingsdagar',
      url: '/spelvarde/meet',
      icon: 'icon-flag',
    },
    {
      name: 'Omgångar',
      url: '/spelvarde/round',
      icon: 'icon-calculator',
    },
    {
      name: 'Skribenter',
      url: '/spelvarde/authors',
      icon: 'icon-people',
    },
    {
      name: 'Rapporter',
      url: '/spelvarde/reports',
      icon: 'icon-graph',
      children: [
        {
          name: 'Tipsresultat',
          url: '/spelvarde/reports/system_results',
        },
        {
          name: 'Tipster-statistik ',
          url: '/spelvarde/reports/tipster-statistics',
        },
      ],
    },
  ],
  travronden_spel: [
    {
      title: true,
      name: 'Travronden Spel',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Innehåll',
      url: '/travronden_spel/content',
      icon: 'icon-list',
    },
    {
      name: 'Tävlingsdagar',
      url: '/travronden_spel/meet',
      icon: 'icon-flag',
    },
    {
      name: 'Omgångar',
      url: '/travronden_spel/round',
      icon: 'icon-calculator',
    },
    {
      name: 'Skribenter',
      url: '/travronden_spel/authors',
      icon: 'icon-people',
    },
    {
      name: 'Rapporter',
      url: '/travronden_spel/reports',
      icon: 'icon-graph',
      children: [
        {
          name: 'Tipsresultat',
          url: '/travronden_spel/reports/system_results',
        },
        {
          name: 'Tipster-statistik ',
          url: '/travronden_spel/reports/tipster-statistics',
        },
      ],
    },
  ],
  travronden: [
    {
      title: true,
      name: 'Travronden',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Innehåll',
      url: '/travronden/content',
      icon: 'icon-list',
    },
    {
      name: 'Nyhetsnotis',
      url: '/travronden/push_notifications',
      icon: 'icon-bell',
    },
    {
      name: 'Placerat innehåll',
      url: '/travronden/desked-content',
      icon: 'icon-pin',
    },
    {
      name: 'Skribenter',
      url: '/travronden/authors',
      icon: 'icon-people',
    },
  ],
  joker_sport: [
    {
      title: true,
      name: 'Joker Sport',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Innehåll',
      url: '/joker_sport/content',
      icon: 'icon-list',
    },
    {
      name: 'Omgångar',
      url: '/joker_sport/rounds',
      icon: 'icon-calculator',
    },
    {
      name: 'Skribenter',
      url: '/joker_sport/authors',
      icon: 'icon-people',
    },
  ],
  jokersystemet: [
    {
      title: true,
      name: 'Jokersystemet',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Innehåll',
      url: '/jokersystemet/content',
      icon: 'icon-list',
    },
  ],
  jokersystemet_no: [
    {
      title: true,
      name: 'Jokersystemet NO',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Innehåll',
      url: '/jokersystemet_no/content',
      icon: 'icon-list',
    },
    {
      name: 'Skribenter',
      url: '/jokersystemet_no/authors',
      icon: 'icon-people',
    },
  ],
  trais: [
    {
      title: true,
      name: 'TRAIS',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Tävlingsdagar',
      url: '/trais/meet',
      icon: 'icon-flag',
    },
    {
      name: 'V75 Kalender',
      url: '/trais/v75-calendar',
      icon: 'icon-calendar',
    },
    {
      name: 'Exporter',
      url: '/trais/exports',
      icon: 'fa fa-download',
    },
  ],
  trhub: [
    {
      title: true,
      name: 'TR Hub',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Innehåll',
      url: '/trhub/content',
      icon: 'icon-list',
    },
  ],
  trmedia: [
    {
      title: true,
      name: 'TR Media',
      wrapper: {
        element: '',
        attributes: {},
      },
      class: '',
    },
    {
      name: 'Innehåll',
      url: '/trmedia/content',
      icon: 'icon-list',
    },
    {
      name: 'Skribenter',
      url: '/trmedia/authors',
      icon: 'icon-people',
    },
  ],
};

export const getNav = () => {
  const user = auth.getUser() || {};
  let items = [
    {
      name: 'Sajter',
      url: '/sites',
      icon: 'icon-globe',
    },
  ];

  Object.keys(user.groups || {})
    .sort((a, b) => a.localeCompare(b))
    .map(site => {
      if (nav[site] && auth.inGroup(site)) {
        items = [...items, ...nav[site]];
      }
      return site;
    });

  return items.length > 1 ? items : [];
};
